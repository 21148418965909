<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">SMS 리스트</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
<!--          <div class="form-inp sm">-->
<!--            <v-select-->
<!--                v-model="aspCustKey"-->
<!--                :items="aspCustList"-->
<!--                item-text="CD_NM"-->
<!--                item-value="CD"-->
<!--                outlined-->
<!--                hide-details-->
<!--                placeholder="선택하세요"-->
<!--            >-->
<!--              <template v-slot:label>-->
<!--                회사구분-->
<!--                <v-icon color="#EF9191">mdi-circle-small</v-icon>-->
<!--              </template>-->
<!--            </v-select>-->
<!--          </div>-->
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  @change="startDate"
              ></v-date-picker>
              <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  @change="endDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
              >확인</v-btn>
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select
                v-model="IN_OUT_DIV"
                :items="IN_OUT_DIV_LIST"
                item-text="title"
                outlined hide-details
                placeholder="선택하세요"
                label="발송구분"
            >
              <template v-slot:label>
                발송구분
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-text-field
                class="form-inp full"
                name="INLNE_NO"
                label="본인내선번호"
                outlined
                hide-details
                v-model="INLNE_NO"
                @keyup.enter="searchSmsList"
            ></v-text-field>
          </div>
          <div class="form-inp sm ml-2">
            <v-text-field
                class="form-inp full"
                name="SEARCH_INLNE_NO"
                label="검색내선번호"
                outlined
                hide-details
                v-model="SEARCH_INLNE_NO"
                @keyup.enter="searchSmsList"
            ></v-text-field>
          </div>
          <div class="form-inp sm ml-2">
            <v-text-field
                class="form-inp full"
                name="SND_TEL_NO"
                label="전화번호"
                outlined
                hide-details
                v-model="SND_TEL_NO"
                @keyup.enter="searchSmsList"
            ></v-text-field>
          </div>
          <div class="form-inp ml-2">
            <v-text-field
                class="form-inp full"
                name="SND_MSG"
                label="문자내용"
                outlined
                hide-details
                v-model="SND_MSG"
                @keyup.enter="searchSmsList"
            ></v-text-field>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="searchSmsList"> 조회 </v-btn>
        </div>
      </div>
    </div>
    <!-- SMS 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">SMS 목록
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')" outlined class="btn-point ml-2" @click="downloadExcel('mainTable', 'SMS 리스트')">엑셀다운로드</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            fixed-header
            height="549px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            item-key="ROW_NUMBER"
            hide-default-footer
            class="grid-default"
            id="mainTable"
            single-select
            :item-class="isActiveRow"
            @click:row="rowSelect"
            @page-count="pageCount = $event"
            :page.sync="page"
            :items-per-page=itemsPerPage
        >
          <template v-slot:[`item.telno`]="{ item }" >
            <div>{{ phoneHyphen(item.telno) }}</div>
          </template>
          <template v-slot:[`item.type`]="{ item }" >
            <div>{{ convertType(item.type) }}</div>
          </template>
          <template v-slot:[`item.status`]="{ item }" >
            <div>{{ convertStatus(item.status) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible" next-icon="svg-paging-next"
                        prev-icon="svg-paging-prev"></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import _ from'lodash';

export default {
  mixins: [mixin],
  name: "MENU_M110801", //name은 'MENU_' + 파일명 조합

  components: {

  },
  data() {
    return {
      aspCustKey: "",
      selectedDrop: [],
      dialogVueDropzone: false,
      menu1: false,
      menu2: false,
      treeItems: [],
      IN_OUT_DIV_LIST: [
        { title: "전체", value: "" },
        { title: "수신", value: "IN" },
        { title: "발신", value: "OUT" },
      ],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      detailDates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      gridDataHeaders: [
        { text: "No", align: "center", value: "ROW_NUMBER", width: "70px", sortable: true, },
        { text: "내선번호", value: "ext", align: "left", sortable: true },
        { text: "상담원명", value: "agtname", align: "left", sortable: true },
        { text: "시간", value: "time", align: "left", sortable: true },
        { text: "발신구분", value: "type", align: "left", sortable: true },
        { text: "발신결과", value: "status", align: "left", sortable: true },
        { text: "문자내용", value: "msg", align: "left", sortable: true },
        { text: "전화번호", value: "telno", align: "left", sortable: true },

      ],
      gridDataText: [],
      page: 1,
      pageCount: 30,
      itemsPerPage: 30,
      totalVisible: 10,

      selectedRow: null,
      checkedList: [],

      textareaRules: [v => v.length <= 80 || '80자 제한'],
      textareaValue: '80자 제한!',
      IN_OUT_DIV: '',
      SND_TEL_NO:'',
      SND_MSG:'',
      INLNE_NO:'',
      SEARCH_INLNE_NO:'',

      alertMsg: {
        comChk: "회사구분란에서 회사를 선택 후 이용해주십시오.",
      },
      alertIcon: {
        noti: "svg-done-lg",
        err: "svg-error-lg",
      },

      alertType: {
        default: "default",
        confirm: "confirm",
      },
    }

  },
  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    startDetailDate(e) {
      this.detailDates[0] = e;
    },
    endDetailDate(e) {
      this.detailDates[1] = e;
    },
    async searchSmsList(){

      // SMS 목록 조회
      let smsListUrl = "/api/phone/sms/list/list";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = smsListUrl;
      requestData.headers["SERVICE"] = "phone.sms.manage.list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey;        //회사코드
      requestData.sendData["SND_TEL_NO"] = this.SND_TEL_NO;             //발신번호
      requestData.sendData["SND_MSG"] = this.SND_MSG;                   //문자내용
      requestData.sendData["INLNE_NO"] = this.INLNE_NO;                 //본인내선번호
      requestData.sendData["SEARCH_INLNE_NO"] = this.SEARCH_INLNE_NO;   //검색내선번호
      requestData.sendData["IN_OUT_DIV"] = this.IN_OUT_DIV;             //발송구분
      requestData.sendData["START_DATE"] = this.dates[0];               //시작일자
      requestData.sendData["END_DATE"] = this.dates[1];                 //종료일자

      this.gridDataText = [];
      let response = await this.common_postCall(requestData);
      let data = response.DATA;
      for(let i in data){
        data[i].ROW_NUMBER = ++i;
      }
      if(data.length > 0 && !_.isNil(data[0].telno)) {
        this.gridDataText = [...this.gridDataText, ...data];
      }
    },
    rowSelect(idx) {
      this.selectedRow = idx;
    },
    isActiveRow(item){
      const activeClass = item === this.selectedRow ? 'active' : '';
      return activeClass;
    },
    phoneHyphen(number) {
      //핸드폰 번호 형식 만들기
      if (!number) return "";
      var phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
    convertType(item) {
      //발신구분
      if (!item) return "";
      if(item=="SEND"){
        item = "발신";
      }else if(item=="RECV"){
        item = "수신";
      }
      return item;
    },
    convertStatus(item) {
      //발신결과
      if (!item) return "";
      if(item=="Sent"){
        item = "전송완료";
      }else if(item=="Sending"){
        item = "전송중";
      }
      return item;
    },
    downloadExcel(tableId, fileNm){
      //엑셀다운로드
      this.table_id = tableId;
      this.file_name = fileNm;
      this.mixin_common_exportExcel();
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    detailDateRangeText() {
      var newStartDate = this.detailDates[0];
      var newEndDate = this.detailDates[1];
      this.detailDateRange = newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  }
};

</script>